.login-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signout-btn {
  margin-top: 30px;
  width: 100px;
}
