.find-huddle-layout {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
}

.find-huddle-view {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  column-gap: 1rem;
  row-gap: 1rem;
}

.map-layout {
  margin-top: -70px;
  flex-grow: 1;
  flex-basis: 534px;
}

.room-info-layout {
  flex-basis: 234px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: stretch;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
}

.find-huddle-layout h1,
h1:hover {
  text-align: center;
  font-family: 'EMprint-Regular';
  margin: 0;
  cursor: default;
}

.find-huddle-loading {
  align-self: center;
}

@media screen and (max-width: 767px) {
  .find-huddle-view {
    flex-direction: column;
  }

  .map-layout {
    flex-basis: auto;
  }

  .room-info-layout {
    flex-basis: auto;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
