.card-status,
.card-status:hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 1rem;
  height: 5rem;
  width: auto;
  overflow: hidden;
  background-color: #eeeeee;
  font-family: 'EMprint-Regular';
  cursor: default;
}

.card-status .status {
  flex-basis: 2rem;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
}

.card-status .status.available {
  background-color: #559f3b;
}

.card-status .status.occupied {
  background-color: #dd0f0f;
}

.card-desc {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 0rem;
  padding-left: 1rem;
}

.card-desc .room {
  font-size: large;
}

.card-desc .room-status.available {
  color: #559f3b;
}

.card-desc .room-status.occupied {
  color: #dd0f0f;
}

@media screen and (max-width: 767px) {
  .card-status {
    flex-direction: column;
  }

  .card-status {
    width: 7rem;
    height: 6rem;
  }

  .card-desc {
    justify-content: center;
    padding-left: 0rem;
  }
}
