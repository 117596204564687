
.sidebar-menu a {
  text-decoration: none;
}

.sidebar-menu .menu-header {
  display: flex;
  justify-content: space-between;
  color: #e1e9fc;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-family: 'EMprint-Regular';
  font-size: 18px;
}

.sidebar-menu .menu-header:hover {
  background: #252831;
  border-left: 4px solid #632ce4;
  cursor: pointer;
}

.sidebar-menu .menu-item {
  height: 60px;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  font-weight: 650;
  color: white;
  font-size: 30px;
  font-family: 'EMprint-Regular';
  border-bottom: 1px solid #e1e9fc;
}

.sidebar-menu .menu-item:hover {
  background: #3a397b;
  cursor: pointer;
}
