@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'EMprint-light';
  src: local('EMprint'), url(./fonts/EMprint-Light.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'EMprint-Semibold';
  src: local('EMprint'), url(./fonts/EMprint-Semibold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'EMprint-Regular';
  src: local('EMprint'), url(./fonts/EMprint-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'EMprint-Bold';
  src: local('EMprint'), url(./fonts/EMprint-Bold.ttf) format('truetype');
  font-display: swap;
}

/** Set for loading spin **/
.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 0.5s infinite linear;
  color: #0c69b0;
  width: 3rem;
  height: 3rem;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
