.find-huddle-logo {
  padding-left: 50px;
  height: 100%;
  font-weight: 650;
  color: #f5f5f5;
  font-size: 30px;
  font-family: 'EMprint-Regular';
}

.find-huddle-logo:hover {
  text-decoration: none;
  color: #d5eaff;
}

.nav-logo-section {
  display: flex;
  flex: 1;
  padding-top: 20px;
  padding-left: 50px;
  height: 100%;
  font-weight: 650;
  color: #f5f5f5;
  font-size: 30px;
  font-family: 'EMprint-Regular';
}

.nav-menu-section {
  padding-left: 2rem;
}

.nav-menu {
  color: white;
  width: 30px;
  height: 30px;
}

.nav-menu:hover {
  color: #d5eaff;
  cursor: pointer;
}

.nav {
  background-image: linear-gradient(45deg, #3a397b 0, #0c69b0 100%);
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 2rem;
  z-index: 10;
}

.nav-signout-btn {
  padding-right: 2rem;
}
