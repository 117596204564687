article.main-content {
  display: flex;
  flex: 2 2 960px;
  justify-content: center;
  overflow: auto;
}

.main-content .container {
  height: fit-content;
  width: 960px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding-top: 1rem;
}

@media screen and (max-width: 960px) {
  article.main-content {
    flex: 2 2 768px;
  }

  .main-content .container {
    width: 768px;
  }
}
