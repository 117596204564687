.home-page .home-building-list {
  padding-top: 50px;
  display: flex;
  justify-content: space-around;
  font-family: 'EMprint-Semibold';
}

.home-welcome-header {
  text-align: center;
  font-size: 40px;
  padding-top: 50px;
  font-weight: 650;
}

.home-welcome-text {
  text-align: center;
  padding: 10px;
  font-family: 'EMprint-Regular';
}

.home-floor-list {
  flex: 1;
  text-align: center;
}

.home-floor-list .building-name {
  color: #111122;
  /* height: 3.5rem; // Used when open another building */
}

.home-floor-list .floors {
  padding-top: 5px;
}

.home-floor-list .floor {
  text-decoration: none;
  font-weight: 550;
  color: #0c69b0;
  font-size: large;
}

.home-floor-list .floor:hover {
  color: red;
}
