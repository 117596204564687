/* CSS for sidebar */
.sidebar-close-section {
  margin-left: 1rem;
  font-size: 1.5rem;
  color: white;
  height: 75px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sidebar-close-icon {
  color: white;
  height: 30px;
}

.sidebar-close-icon:hover {
  cursor: pointer;
  color: #b1b1b1;
}

.sidebar {
  background: linear-gradient(#3a397b 0, #0c69b0 100%);
  width: 250px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  transition: 350ms;

  overflow-y: auto;
}

.sidebar.close {
  left: -100%;
}

.sidebar.open {
  left: 0;
}
