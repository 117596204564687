.body-main {
  display: flex;
  height: 100vh;
  flex-direction: column;
}
/* .container {
  height: fit-content;
  width: 768px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 1rem;
} */

/* @media screen and (max-width: 1023px) {
  .body-main {
    flex-direction: column;
  }
} */
